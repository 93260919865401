import './style.css'
import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { Container, Row, Col, Hidden, Visible } from 'react-grid-system'
import GatsbyImage from 'gatsby-image'

import Button from '../../../components/Button'
import I18nLink from '../../../components/I18nLink'
import Space from '../../../components/Space'
import Carousel from './components/Carousel'
import FeatureItem from './components/FeatureItem'

import iconSmallArrowRightWhite from '../../../img/icons/icon_small_arrow_right_white.svg'

const backgroundClass = classNames({
  'features-list-background': true,
})

const containerClass = classNames({
  'features-list-container': true,
})

const mobileContainerClass = classNames({
  'features-list-mobile-container': true,
})

class List extends Component {

  constructor(props) {
    super(props)

    const {
      data,
      intl: {
        formatMessage,
      },
    } = this.props

    const features = [
      {
        id: 'ai',
        displayIndex: 1,
        picture: data.iaPicture.childImageSharp.fluid,
        title: formatMessage({ id: 'features.list.ai.title' }),
        description: formatMessage({ id: 'features.list.ai.description' }),
        actionText: formatMessage({ id: 'features.list.ai.action' }),
        actionPath: formatMessage({ id: 'features.list.ai.uri' }),
        actionIcon: iconSmallArrowRightWhite,
      },
      {
        id: 'security',
        displayIndex: 4,
        picture: data.securityPicture.childImageSharp.fluid,
        title: formatMessage({ id: 'features.list.security.title' }),
        description: formatMessage({ id: 'features.list.security.description' }),
        actionText: formatMessage({ id: 'features.list.security.action' }),
        actionPath: formatMessage({ id: 'features.list.security.uri' }),
        actionIcon: iconSmallArrowRightWhite,
      },
      {
        id: 'sources',
        displayIndex: 2,
        picture: data.sourcesPicture.childImageSharp.fluid,
        title: formatMessage({ id: 'features.list.sources.title' }),
        description: formatMessage({ id: 'features.list.sources.description' }),
        actionText: formatMessage({ id: 'features.list.sources.action' }),
        actionPath: formatMessage({ id: 'features.list.sources.uri' }),
        actionIcon: iconSmallArrowRightWhite,
      },
      {
        id: 'displays',
        displayIndex: 5,
        picture: data.displaysPicture.childImageSharp.fluid,
        title: formatMessage({ id: 'features.list.displays.title' }),
        description: formatMessage({ id: 'features.list.displays.description' }),
        actionText: formatMessage({ id: 'features.list.displays.action' }),
        actionPath: formatMessage({ id: 'features.list.displays.uri' }),
        actionIcon: iconSmallArrowRightWhite,
      },
      {
        id: 'privacy',
        displayIndex: 3,
        picture: data.privacyPicture.childImageSharp.fluid,
        title: formatMessage({ id: 'features.list.privacy.title' }),
        description: formatMessage({ id: 'features.list.privacy.description' }),
        actionText: formatMessage({ id: 'features.list.privacy.action' }),
        actionPath: formatMessage({ id: 'features.list.privacy.uri' }),
        actionIcon: iconSmallArrowRightWhite,
      },
      // TODO: Uncomment when this will be needed
      // {
      //   id: 'ocr',
      //   picture: data.ocrPicture.childImageSharp.fluid,
      //   title: formatMessage({ id: 'features.list.ocr.title' }),
      //   description: formatMessage({ id: 'features.list.ocr.description' }),
      // },
      // {
      //   id: 'correction',
      //   picture: data.correctionPicture.childImageSharp.fluid,
      //   title: formatMessage({ id: 'features.list.correction.title' }),
      //   description: formatMessage({ id: 'features.list.correction.description' }),
      // },
      // {
      //   id: 'completion',
      //   picture: data.completionPicture.childImageSharp.fluid,
      //   title: formatMessage({ id: 'features.list.completion.title' }),
      //   description: formatMessage({ id: 'features.list.completion.description' }),
      // },
    ]

    this.state = {
      features,
      selectedFeatureIndex: 0,
    }
  }

  // Methods
  selectPreviousFeature = () => {
    const {
      features,
      selectedFeatureIndex,
    } = this.state

    const currentDisplayIndex = features[selectedFeatureIndex].displayIndex
    const nextFeatureIndex = features.findIndex(feature => feature.displayIndex === currentDisplayIndex - 1)

    let newIndex = features.findIndex(feature => feature.displayIndex === features.length)
    if (nextFeatureIndex !== -1) {
      newIndex = nextFeatureIndex
    }

    this.selectFeature(newIndex)
  }

  selectNextFeature = () => {
    const {
      features,
      selectedFeatureIndex,
    } = this.state

    const currentDisplayIndex = features[selectedFeatureIndex].displayIndex
    const nextFeatureIndex = features.findIndex(feature => feature.displayIndex === currentDisplayIndex + 1)

    let newIndex = 0
    if (nextFeatureIndex > -1) {
      newIndex = nextFeatureIndex
    }

    this.selectFeature(newIndex)
  }

  selectFeature = (newIndex) => {
    this.setState({
      selectedFeatureIndex: newIndex,
    })
  }


  // Render
  render() {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <div className={backgroundClass}>
        <Container>
          <div className={containerClass}>
            <Row>
              <Col xs={12} md={10} offset={{ md: 1 }}>
                <Space type="xl" />
                <h2>
                  {formatMessage({ id: 'features.list.title' })}
                </h2>
                <Space type="xl" />
              </Col>
            </Row>

            <Hidden xs sm>
              {this.renderDesktop()}
            </Hidden>

            <Visible xs sm>
              {this.renderMobile()}
            </Visible>
          </div>
        </Container>
      </div>
    )
  }

  renderDesktop = () => {
    const {
      features,
      selectedFeatureIndex,
    } = this.state

    return (
      <Row>
        <Col md={10} offset={{ md: 1 }}>
          <Carousel
            feature={features[selectedFeatureIndex]}
            onPreviousClick={() => this.selectPreviousFeature()}
            onNextClick={() => this.selectNextFeature()}
          />
          <Space type="l" />
        </Col>
        {this.renderFeatureItems()}
      </Row>
    )
  }

  renderFeatureItems = () => {
    const {
      features,
      selectedFeatureIndex,
    } = this.state

    const list = features.map((feature, index) => (
      <Col
        key={feature.id}
        md={5}
        offset={{ md: (index & 1) ? 0 : 1 }}
      >
        <FeatureItem
          index={feature.displayIndex}
          feature={feature}
          isSelected={index === selectedFeatureIndex}
          onClick={() => this.selectFeature(index)}
        />
        <Space type="xxs" />
      </Col>
    ))

    return list
  }

  renderMobile = () => {
    const {
      features,
    } = this.state

    const list = [...features]
      .sort((lhs, rhs) => {
        {
          if (lhs.displayIndex < rhs.displayIndex)
            return -1
          if (lhs.displayIndex > rhs.displayIndex)
            return 1
          return 0
        }
      })
      .map(feature => (
        <div
          key={feature.id}
        >
          <GatsbyImage
            fluid={feature.picture}
            alt={feature.id}
            style={{
              maxWidth: '100%',
            }}
          />
          <Space type="xl" />
          <h3>
            {feature.title}
          </h3>
          <Space type="xl" />
          <p>
            {feature.description}
          </p>
          {
            feature.actionText && feature.actionPath && (
              <Fragment>
                <Space type="xs" />
                <I18nLink to={feature.actionPath}>
                  <Button
                    text={feature.actionText}
                    color="purple"
                    iconRight={iconSmallArrowRightWhite}
                    isExpanded
                  />
                </I18nLink>
              </Fragment>
            )
          }
        </div>
      ))

    return (
      <div className={mobileContainerClass}>
        {list}
      </div>
    )
  }
}

export default injectIntl(List)
