import React from 'react'
import { injectIntl } from 'react-intl'
import { graphql } from 'gatsby'

import withContext from '../components/withContext'
import injectContext from '../components/injectContext'
import withIntl from '../components/withIntl'
import Space from '../components/Space'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Navigation from '../scenes/Navigation'
import Header from '../scenes/Header'
import List from '../scenes/Features/List'
import Gains from '../scenes/Features/Gains'
import OptimizeWorkflow from '../scenes/OptimizeWorkflow'
import SecurityMini from '../scenes/SecurityMini'
import Footer from '../scenes/Footer'

import headerPicture from '../img/artworks/artwork_features_header.png'

const GATSBY_APP_URL = process.env.GATSBY_APP_URL

const Features = ({ intl: { formatMessage }, data }) => (
  <Layout>
    <SEO
      metaTitle={formatMessage({ id: 'meta.features.title' })}
      metaDescription={formatMessage({ id: 'meta.features.description' })}
      metaOGImage={headerPicture}
    />
    <Navigation />
    <Header
      crumbs={[
        { name: formatMessage({ id: 'breadcrumb.wuha' }), path: '/' },
        { name: formatMessage({ id: 'breadcrumb.features' }), path: '/features' },
      ]}
      artworkPicture={data.headerPicture.childImageSharp.fluid}
      artworkWidth={714}
      title={formatMessage({ id: 'features.header.title' })}
      description={formatMessage({ id: 'features.header.description' })}
      actionText={formatMessage({ id: 'features.header.action.connect' })}
      actionURL={`${GATSBY_APP_URL}/sign-up?utm_source=wuha&utm_medium=cta&utm_content=features-header`}
    />
    <Space type="giant" />
    <List data={data} />
    <Space type="giant" />
    <Gains data={data} />
    <Space type="xxl" />
    <OptimizeWorkflow
      utmContent="features-optimize"
    />
    <Space type="giant" />
    <SecurityMini />
    <Footer />
  </Layout>
)

export const pageQuery = graphql`
  query {
    headerPicture: file(relativePath: { eq: "artworks/artwork_features_header.png" }) {
      ...fluidImage
    }
    iaPicture: file(relativePath: { eq: "artworks/artwork_features_miniblock_1.png" }) {
      ...fluidImage
    }
    sourcesPicture: file(relativePath: { eq: "artworks/artwork_features_miniblock_2.png" }) {
      ...fluidImage
    }
    displaysPicture: file(relativePath: { eq: "artworks/artwork_features_miniblock_3.png" }) {
      ...fluidImage
    }
    privacyPicture: file(relativePath: { eq: "artworks/artwork_features_miniblock_4.png" }) {
      ...fluidImage
    }
    securityPicture: file(relativePath: { eq: "artworks/artwork_features_miniblock_5.png" }) {
      ...fluidImage
    }
    gain1Picture: file(relativePath: { eq: "artworks/artwork_reassurance_1.jpg" }) {
      ...fixedImage
    }
    gain2Picture: file(relativePath: { eq: "artworks/artwork_reassurance_2.jpg" }) {
      ...fixedImage
    }
    gain3Picture: file(relativePath: { eq: "artworks/artwork_reassurance_3.jpg" }) {
      ...fixedImage
    }
    gain4Picture: file(relativePath: { eq: "artworks/artwork_reassurance_4.jpg" }) {
      ...fixedImage
    }
  }
`

// TODO: Uncomment and add to above query when this will be needed
// ocrPicture: file(relativePath: { eq: "artworks/artwork_features_miniblock_6.png" }) {
//   ...fluidImage
// }
// correctionPicture: file(relativePath: { eq: "artworks/artwork_features_miniblock_7.png" }) {
//   ...fluidImage
// }
// completionPicture: file(relativePath: { eq: "artworks/artwork_features_miniblock_8.png" }) {
//   ...fluidImage
// }

export default withContext(injectContext(withIntl(injectIntl(Features))))
