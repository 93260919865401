import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import I18nLink from '../../../../../components/I18nLink'
import Inline from '../../../../../components/Inline'

const numberTextClass = classNames({
  'features-list-feature-item-number-text': true,
})

const titleTextClass = classNames({
  'features-list-feature-item-title-text': true,
})

const actionTextClass = classNames({
  'features-list-feature-item-action-text': true,
})

class FeatureItem extends Component {

  render() {
    const {
      index,
      feature,
      isSelected,
      onClick,
    } = this.props

    if (!feature) {
      return null
    }

    const containerClass = classNames({
      'features-list-feature-item-container': true,
      'features-list-feature-item-container-selected': isSelected,
    })

    const paddedIndex = index.toString().padStart(2, '0')

    return (
      <div
        className={containerClass}
        onClick={onClick}
      >
        <p className={numberTextClass}>
          {paddedIndex}
        </p>
        <Inline type="xs" />
        <p className={titleTextClass}>
          {feature.title}
        </p>
        {
          feature.actionText && (
            <I18nLink to={feature.actionPath}>
              <p className={actionTextClass}>
                {feature.actionText}
              </p>
            </I18nLink>
          )
        }
      </div>
    )
  }
}

FeatureItem.propTypes = {
  index: PropTypes.number,
  feature: PropTypes.object,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
}

FeatureItem.defaultProps = {
  index: undefined,
  feature: undefined,
  isSelected: false,
  onClick: undefined,
}

export default FeatureItem
