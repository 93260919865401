import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Image from '../../../../../../../components/Image'
import iconSmallArrowLeftBlue from '../../../../../../../img/icons/icon_small_arrow_left_blue.svg'
import iconSmallArrowRightBlue from '../../../../../../../img/icons/icon_small_arrow_right_blue.svg'

const DIRECTIONS = ['left', 'right']
const DIRECTIONS_IMAGES = {
  left: iconSmallArrowLeftBlue,
  right: iconSmallArrowRightBlue,
}

const containerClass = classNames({
  'features-list-carousel-direction-button-container': true,
})

const backgroundClass = classNames({
  'features-list-carousel-direction-button-background': true,
})

class DirectionButton extends Component {

  render() {
    const {
      direction,
      onClick,
    } = this.props

    return (
      <div
        className={containerClass}
        onClick={onClick}
      >
        <div className={backgroundClass} />
        <Image
          src={DIRECTIONS_IMAGES[direction]}
          alt={direction}
          style={{
            zIndex: 1,
          }}
        />
      </div>
    )
  }
}

DirectionButton.propTypes = {
  direction: PropTypes.oneOf(DIRECTIONS).isRequired,
  onClick: PropTypes.func,
}

DirectionButton.defaultProps = {
  direction: DIRECTIONS[0],
  onClick: undefined,
}

export default DirectionButton
