import './style.css'
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import GatsbyImage from 'gatsby-image'

import Button from '../../../../../components/Button'
import I18nLink from '../../../../../components/I18nLink'
import Image from '../../../../../components/Image'
import Inline from '../../../../../components/Inline'
import Space from '../../../../../components/Space'
import DirectionButton from './components/DirectionButton'

const containerClass = classNames({
  'features-list-carousel-container': true,
})

const imageContainerClass = classNames({
  'features-list-carousel-image-container': true,
})

const descriptionContainerClass = classNames({
  'features-list-carousel-description-container': true,
})

const descriptionButtonsContainerClass = classNames({
  'features-list-carousel-description-buttons-container': true,
})

class Carousel extends Component {

  render() {
    const {
      feature,
      onPreviousClick,
      onNextClick,
    } = this.props

    if (!feature) {
      return null
    }

    return (
      <div className={containerClass}>
        <div className={imageContainerClass}>
          <GatsbyImage
            fluid={feature.picture}
            alt={feature.id}
            style={{
              width: '100%',
              maxWidth: '100%',
            }}
          />
        </div>

        <div className={descriptionContainerClass}>
          <h4>
            {feature.title}
          </h4>
          <Space type="m" />
          <p>
            {feature.description}
          </p>
          {
            feature.actionText && (
              <Fragment>
                <Space type="l" />
                <I18nLink to={feature.actionPath}>
                  <Button
                    text={feature.actionText}
                    color="purple"
                    iconRight={feature.actionIcon}
                  />
                </I18nLink>
              </Fragment>
            )
          }
          <Space type="l" />
          <div className={descriptionButtonsContainerClass}>
            <DirectionButton
              direction="left"
              onClick={onPreviousClick}
            />
            <Inline type="s" />
            <DirectionButton
              direction="right"
              onClick={onNextClick}
            />
          </div>
        </div>
      </div>
    )
  }
}

Carousel.propTypes = {
  feature: PropTypes.object,
  onPreviousClick: PropTypes.func,
  onNextClick: PropTypes.func,
}

Carousel.defaultProps = {
  feature: undefined,
  onPreviousClick: undefined,
  onNextClick: undefined,
}

export default Carousel
