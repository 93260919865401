import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import GatsbyImage from 'gatsby-image'

import Space from '../../../../../components/Space'

const containerClass = classNames({
  'features-gains-gain-container': true,
})

const descriptionTextClass = classNames({
  'features-gains-gain-description-text': true,
})

class Gain extends Component {

  render() {
    const {
      picture,
      alt,
      text,
    } = this.props

    return (
      <div className={containerClass}>
        <GatsbyImage
          fixed={picture}
          alt={alt}
          width={96}
          height={96}
          style={{
            width: 96,
            height: 96,
          }}
        />
        <Space type="xs" />
        <p className={descriptionTextClass}>
          {text}
        </p>
      </div>
    )
  }
}

Gain.propTypes = {
  picture: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

Gain.defaultProps = {
  picture: undefined,
  alt: undefined,
  text: undefined,
}

export default Gain
