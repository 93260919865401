import './style.css'
import React, { Component } from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { Container, Row, Col } from 'react-grid-system'

import Space from '../../../components/Space'
import Gain from './components/Gain'

const containerClass = classNames({
  'features-gains-container': true,
})

const descriptionTextClass = classNames({
  'features-gains-description-text': true,
})

const listContainerClass = classNames({
  'features-gains-list-container': true,
})

class Gains extends Component {

  render() {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Container>
        <Row>
          <Col xs={12} md={10} offset={{ md: 1 }}>
            <div className={containerClass}>
              <h2>
                {formatMessage({ id: 'features.gains.title' })}
              </h2>
              <Space type="s" />
              <p className={descriptionTextClass}>
                {formatMessage({ id: 'features.gains.description' })}
              </p>
              <Space type="l" />
              {this.renderGains()}
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  renderGains = () => {
    const {
      data,
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <div className={listContainerClass}>
        <Gain
          picture={data.gain1Picture.childImageSharp.fixed}
          text={formatMessage({ id: 'features.gains.1.description' })}
          alt={formatMessage({ id: 'features.gains.1.alt' })}
        />
        <Gain
          picture={data.gain2Picture.childImageSharp.fixed}
          text={formatMessage({ id: 'features.gains.2.description' })}
          alt={formatMessage({ id: 'features.gains.2.alt' })}
        />
        <Gain
          picture={data.gain3Picture.childImageSharp.fixed}
          text={formatMessage({ id: 'features.gains.3.description' })}
          alt={formatMessage({ id: 'features.gains.3.alt' })}
        />
        <Gain
          picture={data.gain4Picture.childImageSharp.fixed}
          text={formatMessage({ id: 'features.gains.4.description' })}
          alt={formatMessage({ id: 'features.gains.4.alt' })}
        />
      </div>
    )
  }
}

export default injectIntl(Gains)
